<template>
    <div class="pb-4 d-flex flex-column full-height ticket-bank-wrapper">
        <v-alert v-if="showSuccess" class="mx-4 mt-4" dense text type="success" > Payment success </v-alert>

        <div class="ticket-bank">
            <p class="d-flex flex-column">
                <span>{{$t('ticketBeneficiray')}}</span>
                <span>Ticket <span v-if="basket != null">{{basket.orderNumber}}</span></span>
                <span>{{currentDate}}</span>
            </p>
            <div class="d-flex justify-space-between">
                <div class="d-flex" style="width: 80%">
                    <span>Qty</span>
                    <span class="ml-2">Product</span>
                </div>
                <span>Price</span>
            </div>
            <div class="divider my-4"></div>
    
            <div class="d-flex flex-column mt-4">
                <template v-if="basket != null">
                    <div v-for="basketProduct in basket.basketProducts" :key="basketProduct.product.id" class="d-flex justify-space-between">
                        <div class="d-flex" style="width: 80%">
                            <span class="mr-3">{{basketProduct.qty}}</span>
                            <span class="ml-2">{{translate(basketProduct.product.name)}}</span>
                        </div>
                        <span>{{formatPrice(basketProduct.product.price)}}</span>
                    </div>
                </template>
            </div>
    
            <div class="divider my-4"></div>
    
            <div v-if="discount" class="d-flex font-italic text-body-2 full-width justify-space-between">
                <p class="ma-0">Coupon (PRW10) :</p>
                <p class="ma-0">-{{formatPrice(totalAmount * 0.1)}}</p>
            </div>
            <div class="d-flex font-weight-bold full-width justify-space-between">
                <p class="ma-0">Total :</p>
                <p v-if="discount" class="ma-0">{{formatPrice(totalAmount * 0.9)}}</p>
                <p v-else class="ma-0">{{formatPrice(totalAmount)}}</p>
            </div>
    
            <div class="d-flex justify-space-between mt-4 little-text">
                <div class="d-flex flex-column">
                    <span>{{$t('ticketBankTVAPercentage')}}</span>
                    <span>{{tvaPercentage}}%</span>
                </div>
                <div class="d-flex flex-column">
                    <span>{{$t('ticketBankHT')}}</span>
                    <span>{{formatPrice(calculateHT())}}</span>
                </div>
                <div class="d-flex flex-column">
                    <span>{{$t('ticketBankTVA')}}</span>
                    <span>{{formatPrice(calculateTVA())}}</span>
                </div>
            </div>    
        </div>
        <div class="mt-3 mb-1 px-4 d-flex full-width">
            <v-spacer></v-spacer>
            <v-btn class="mx-2" color="primary" small fab><v-icon>mdi-share-variant</v-icon></v-btn>
            <v-btn class="mx-2" color="primary" small fab><v-icon>mdi-download</v-icon></v-btn>
        </div>
        <p class="mt-5 text-center mb-1">How do you feel about this experience: </p>
        <div class="mb-5 mt-2 d-flex align-center justify-center full-width">
            <v-btn class="mx-2" x-large icon>
                <v-avatar>
                    <img src="@/assets/img/nps/very-happy.png" alt="alt">
                </v-avatar>
            </v-btn>
            <v-btn class="mx-2" x-large icon>
                <v-avatar>
                    <img src="@/assets/img/nps/happy.png" alt="alt">
                </v-avatar>
            </v-btn>
            <v-btn class="mx-2" x-large icon>
                <v-avatar>
                    <img src="@/assets/img/nps/neutral.png" alt="alt">
                </v-avatar>
            </v-btn>
            <v-btn class="mx-2" x-large icon>
                <v-avatar>
                    <img src="@/assets/img/nps/sad.png" alt="alt">
                </v-avatar>
            </v-btn>
            <v-btn class="mx-2" x-large icon>
                <v-avatar>
                    <img src="@/assets/img/nps/angry.png" alt="alt">
                </v-avatar>
            </v-btn>
        </div>
        <v-img class="align-self-center mb-3" src="@/assets/img/coupon.png" width="80%" max-width="300px" contain/>
        <v-img class="align-self-center" src="@/assets/img/instagram.png" width="80%" max-width="150px" contain/>
        <v-spacer></v-spacer>
        <div class="my-5">
            <TicketSendModal :enabled="activeReceiptBtn"/>
        </div>
    </div>
</template>

<script>
    const moment = require('moment');
    import {pageInfoMixin} from "@/mixins/pageInfoMixin"
    import TicketSendModal from "@/components/TicketSendModal";

    export default {
        name: 'TicketBank',
        mixins: [pageInfoMixin],
        components: { TicketSendModal },
        data(){
            return{
                basket: null,
                totalAmount: 0.0,
                fromRoute: null,
                tvaPercentage: 5.5,
                currentDate: moment().format("DD/MM/YYYY HH:mm:ss"),
                activeReceiptBtn: false,
                showSuccess: false,
                discount: false
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            })
        },
        async created(){
            
            if(this.$route.query.tId){
                let complexId = this.$route.query.tId
                this.basket = await this.getBasketByComplexId(complexId)
                // console.log(this.basket);
                
            }else if(this.basketComplexId){
                await this.validSharedBasketInDatabase({ comment: JSON.stringify({coupon: this.coupon}) } )
                this.showSuccess = true
                this.basket = this.lastTicket
            }
            let comment = JSON.parse(this.basket.comment)
            if(comment.coupon == "PRW10"){
                this.discount = true
            }
            this.updateCoupon(null)
            this.activeReceiptBtn = this.basket != null
            this.totalAmount = this.basket.totalAmount

            let customerPaymentTopic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_TICKET.format({complexId: this.basket.complexId });
            this.$mqtt.publishMqtt(customerPaymentTopic, "", () => {});
        },
        methods: {
            calculateHT(){
                return (this.totalAmount - this.calculateTVA())
            },
            calculateTVA(){
                return (this.totalAmount * (this.tvaPercentage / 100))
            }
        }
    }
</script>
<style scoped>
.ticket-bank .divider{
    width: 100%;
    height: 1px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.ticket-bank .avatar{
    color: #fff;
    background-color: #0c121e;
    float: right;
}

.ticket-bank .little-text{
    font-size: 0.8rem;
}
.ticket-bank-wrapper{
    overflow: auto;
}

.ticket-bank {
    position: relative;
    box-sizing: border-box;
    width: 90%;
    margin: 16px auto 0;
    padding: 20px;
    border-radius: 3px;
    background: #FBFBFB;
    box-shadow: 3px 4px 5px #8080802e;
}

.ticket-bank:before,
.ticket-bank:after {
    content: '';
    position: absolute;
    left: 0px;
    height: 6px;
    width: 100%;
    background: radial-gradient(circle, transparent, transparent 50%, #FBFBFB 50%, #FBFBFB 100% ) -7px -2px / 16px 16px repeat-x;
}

.ticket-bank:before {
    top: -5px;
    transform: rotate(180deg);
}

.ticket-bank:after {
    bottom: -5px;
}
</style>
